









import {
  Authentication,
  AuthServiceType,
  EventbusType,
  EventPayload,
  IEventbus
} from '@movecloser/front-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Inject } from '../../../../../support'
import { AuthMixin, IAuthMixin, UserData, UserModel } from '../../../../auth/shared'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<NavbarUser>({
  name: 'NavbarUser',
  created () {
    this.name = this.authService.user?.firstName ?? null
    this.isPremium = this.isUserPremium ?? false
  },
  mounted () {
    this.eventBus.handle('app:handle-user-refresh', (event: EventPayload<UserData>) => {
      if (event.payload) {
        this.name = event.payload.firstName
      }
    })
  }
})
export class NavbarUser extends Mixins<IAuthMixin>(AuthMixin) {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  public eventBus!: IEventbus

  @Prop({ required: true, type: String })
  public icon!: string

  @Prop({ required: false, type: Boolean, default: false })
  public isNameVisible!: boolean

  public name: string | null = null
  public isPremium: boolean = false
}

export default NavbarUser


























import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

import { Link } from '../../../../../contexts'

import BodyMarginMixin from './BodyMargin.mixin.vue'
import LogoMixin from './Logo.mixin.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<NavbarSimple>({
  name: 'NavbarSimple'
})
export class NavbarSimple extends Mixins(BodyMarginMixin, LogoMixin) {
  /**
   * Determines the `backTo` link.
   */
  @Prop({ type: Object, required: false })
  public readonly link?: Link

  /**
   * Reference for navigation.
   */
  @Ref('navigation-simple')
  public readonly navigationRef?: HTMLElement

  /**
   * Determines whether `backTo` link is present.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' && ('target' in this.link)
  }
}
export default NavbarSimple

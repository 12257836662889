
























import { Component, Prop, Vue } from 'vue-property-decorator'

import { UiMarkdown } from '../../../../../modules/partials/UiMarkdown'
import { ISiteService, SiteServiceType } from '../../../../../contexts'
import { Inject } from '../../../../../support'
import { StructureConfigurable } from '../../../../../support/mixins'

import { TopbarConfig, TopbarLanguage } from '../TopbarContracts'
import { TOPBAR_COMPONENT_KEY, TOPBAR_CONFIG_MAP } from '../Topbar.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<TopBar>({
  name: 'TopBar',
  components: { UiMarkdown },
  created () {
    this.config = this.getComponentConfig(TOPBAR_COMPONENT_KEY, { ...TOPBAR_CONFIG_MAP })
  }
})
export class TopBar extends StructureConfigurable {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: String, required: false })
  public readonly icon?: string

  @Prop({ type: Boolean, required: false })
  public readonly withIcon?: boolean

  @Prop({ type: String, required: false })
  public readonly topBarContent?: string

  @Prop({ type: Boolean, required: true })
  public readonly showLanguageSwitcher?: boolean

  protected config!: TopbarConfig

  public get topBarLanguages (): Array<TopbarLanguage> {
    return this.getConfigProperty('topBarLanguages')
  }

  public get currentLanguage (): string {
    return this.siteService.getActiveSiteLocale()
  }

  public get languages (): Array<TopbarLanguage> {
    return this.topBarLanguages.filter(lang => lang.label !== this.currentLanguage)
  }

  public get hasContent (): boolean {
    return typeof this.topBarContent !== 'undefined' && this.topBarContent.length > 0
  }
}

export default TopBar

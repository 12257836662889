

























































import { Component, Prop } from 'vue-property-decorator'

import { Loader } from '../../../../shared/molecules/Loader'

import { AbstractSearchResults } from './_abstract'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SearchResultsDesktop>({
  name: 'SearchResultsDesktop',
  components: { Loader }
})
export class SearchResultsDesktop extends AbstractSearchResults {
  @Prop({ type: Number, required: true })
  public readonly sliceResults!: number

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldAutoHide!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldSetResultsPosition!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly shouldWatchResize?: boolean

  public get shouldSliceResults (): boolean {
    return typeof this.sliceResults !== 'undefined' && this.sliceResults > 0
  }

  public sameWidth = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    order: 849,
    requires: ['computeStyles'],
    fn: (data: any) => {
      const width = data.offsets.reference.width

      data.styles.width = `${width}px`
      data.offsets.popper.width = width
      data.offsets.popper.top -= 15 // fixme: skad ten odstep...?

      return data
    }
  }
}
export default SearchResultsDesktop

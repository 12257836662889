



















import { Component, Inject as VueInject, Mixins, Prop } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'

import { ISiteService, SiteServiceType } from '../../../../contexts'

import { StructureConfigurable, ComponentsStructureConfig } from '../../../../support/mixins'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { ILocaleContentManager, LocaleContentManagerType } from '../../services'

import { NavbarDesktop, NavbarMobile } from './partials'
import {
  NAVBAR_COMPONENT_KEY,
  NAVBAR_WRAPPER_DEFAULT_CONFIG,
  PossibleStaticLinks,
  possibleStaticLinksRegistry,
  StaticLink
} from './Navbar.config'
import { NavbarProps } from './Navbar.contracts'
import TopBar from './partials/TopBar.vue'
import LogoMixin from './partials/Logo.mixin.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl >
 */
@Component<Navbar>({
  name: 'Navbar',
  components: {
    NavbarMobile,
    NavbarDesktop,
    TopBar
  },
  created (): void {
    this.config = this.getComponentConfig(
      NAVBAR_COMPONENT_KEY,
      { ...NAVBAR_WRAPPER_DEFAULT_CONFIG }
    )

    this.$store.commit('shared/setHasTopBar', this.hasTopBar)
  }
})
export class Navbar extends Mixins<StructureConfigurable>(StructureConfigurable, LogoMixin) {
  @Prop({ type: Array, required: false })
  public readonly externalLinks?: NavbarProps['externalLinks']

  @Prop({ type: Array, required: false })
  public readonly mainLinks?: NavbarProps['mainLinks']

  @Prop({ type: String, required: false })
  public readonly topBarContent?: string

  @Prop({ type: Boolean, required: false })
  public readonly withIcon?: NavbarProps['withIcon']

  @Prop({ type: String, required: false })
  public readonly icon?: NavbarProps['icon']

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(LocaleContentManagerType, false)
  protected readonly localeContentManager?: ILocaleContentManager

  @Inject(SiteServiceType, false)
  public readonly siteService?: ISiteService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  private readonly isMobile!: () => boolean

  /**
   * Determines whether backdrop is visible.
   */
  public isShown: boolean = false

  /**
   * Determines whether there is active menu(s)/tab(s).
   */
  public hasActive: boolean = false

  public config: ComponentsStructureConfig = {}

  /**
   * Determines which Navigation should be rendered.
   */
  public get component (): VueConstructor {
    return this.isMobile() ? NavbarMobile : NavbarDesktop
  }

  /**
   * Closes all active tabs/menus.
   */
  public closeActive (): void {
    this.hasActive = false
    this.onElementUnhover()
  }

  /**
   * Determines whether topbar is present.
   */
  public get hasTopBar (): boolean {
    return typeof this.topBarContent === 'string' && this.topBarContent.length > 0
  }

  public get hasWishlist (): boolean {
    return this.getConfigProperty('hasWishlist')
  }

  /**
   * Determines navbar demand static link icons.
   */
  public get demandStaticLinkIcons (): AnyObject {
    return this.getConfigProperty('staticLinkIcons')
  }

  /**
   * Determines whether lang switcher is showed.
   */
  public get showLanguageSwitcher (): boolean {
    return this.getConfigProperty('showLanguageSwitcher')
  }

  public get showDynamicResults (): boolean {
    return this.getConfigProperty('showDynamicResults')
  }

  /**
   * Determines active site's url address
   */
  public get siteAddress (): string {
    return this.siteService?.getActiveSiteAddress() || ''
  }

  public get possibleStaticLinks (): Record<string, Record<string, string>> {
    return this.getConfigProperty('possibleStaticLinks')
  }

  /**
   * Lists defined static links.
   */
  public get staticLinks (): StaticLink[] {
    const configuredLinks = Object.entries(PossibleStaticLinks).map(([k, v]) => {
      return {
        ...v,
        ...(
          this.possibleStaticLinks
            ? { link: { name: possibleStaticLinksRegistry[this.possibleStaticLinks[k]?.link] } }
            : {}
        )
      }
    })

    if (!Array.isArray(this.demandStaticLinkIcons) && this.demandStaticLinkIcons.length === 0) {
      return configuredLinks
    }

    configuredLinks.forEach((staticLink) => {
      const foundLink = this.demandStaticLinkIcons.find((demandStaticLink: StaticLink) => staticLink.id.toLowerCase() === demandStaticLink.id.toLowerCase())
      if (!foundLink) {
        return
      }

      staticLink.icon = foundLink.icon
    })

    return Object.values(configuredLinks).filter((link) => {
      if (this.localeContentManager && !this.localeContentManager.retrieve<boolean>('user')) {
        return link.id !== 'account'
      }
      return link
    })
  }

  /**
   * Handles elements hover in nav.
   */
  public onElementHover (hasChildren: boolean): void {
    this.hasActive = true
    if (hasChildren) {
      this.isShown = true
      this.setBodyScroll()
    }
  }

  /**
   * Handles elements unhover in nav.
   */
  public onElementUnhover (): void {
    this.isShown = false
    this.setBodyScroll()
  }

  protected setBodyScroll (): void {
    if (this.isMobile()) {
      if (this.isShown) {
        this.lockScroll()
      } else {
        this.unlockScroll()
      }
    }
  }

  private lockScroll (): void {
    if (typeof window !== 'undefined') {
      const scrollY = window.scrollY
      document.body.classList.add('scroll--locked')
      document.body.style.top = `-${scrollY}px`
    }
  }

  private unlockScroll (): void {
    if (typeof window !== 'undefined') {
      document.body.classList.remove('scroll--locked')
      const scrollY = document.body.style.top
      document.body.style.top = ''
      window.scrollTo({
        left: 0,
        top: parseInt(scrollY || '0') * -1,
        // @ts-expect-error tslint doesn't allow 'instant' (even though it is valid according to spec)
        behavior: 'instant'
      })
    }
  }
}

export default Navbar

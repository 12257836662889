



















































import { Component } from 'vue-property-decorator'

import { Loader } from '../../../molecules/Loader'

import { AbstractSearchResults } from './_abstract'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'SearchResultsMobile', components: { Loader } })
export class SearchResultsMobile extends AbstractSearchResults {}
export default SearchResultsMobile













import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import { DisplaySearchResult } from '../../../../contexts'

/**
 * Dummy Search Results component.
 */
@Component({
  name: 'DummySearchResult'
})
export class DummySearchResult extends Vue implements DisplaySearchResult {
  @Prop({ type: Object, required: true })
  public readonly element!: { content: string }

  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean
}
export default DummySearchResult

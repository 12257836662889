

















































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'

import { AllowedContentType, NavigationItem } from '../../../../../contexts'

import { Inject } from '../../../../../support'

import { RouteName as CheckoutRoute } from '../../../../checkout/routes'
import { RouteNames } from '../../../../orders/routes'
import { RouteNames as AuthRouteNames } from '../../../../auth/routes'
import { RouteNames as WishlistRouteNames } from '../../../../wishlist/routes'

import { AuthMixin, IAuthMixin } from '../../../../auth/shared'
import { Drawers } from '../../../../auth/config/modals'
import { Drawers as CheckoutDrawers } from '../../../../checkout/config/modals'
import { BaseCartMixin, IBaseCart } from '../../../../checkout/shared/mixins/base-cart.mixin'
import { StructureConfigurable } from '../../../../../support/mixins'
import {
  ComponentsStructureConfig
} from '../../../../../support/mixins/StructureConfigurable.mixin.vue'

import {
  FavouriteProductsServiceType,
  IFavouriteProductsService
} from '../../../../products/contracts/services'
import {
  BaseWishListMixin,
  IBaseWishListMixin
} from '../../../../wishlist/shared/mixins/base.mixin'

import {
  ILocaleContentManager,
  LocaleContentManagerType
} from '../../../services'
import { DrawerType, IDrawer } from '../../../contracts/services'
import { Loader } from '../../../molecules/Loader'

import { SearchResults } from '../../SearchResults'

import { MobileMenuItemDefinition, NavbarLogo, NavbarProps } from '../Navbar.contracts'
import {
  NAVBAR_MOBILE_COMPONENT_KEY,
  NAVBAR_MOBILE_DEFAULT_CONFIG,
  NavbarTypes,
  PossibleMobileMenuItems,
  StaticLinks
} from '../Navbar.config'
import BodyMarginMixin from './BodyMargin.mixin.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 *
 * @emits - [hover]: When element in bottom nav is selected
 * @emits - [unhover]: When element in bottom nav is unselected.
 */
@Component<NavbarMobile>({
  name: 'NavbarMobile',
  components: {
    Loader,
    SearchResults
  },
  created (): void {
    this.config = this.getComponentConfig(
      NAVBAR_MOBILE_COMPONENT_KEY,
      { ...NAVBAR_MOBILE_DEFAULT_CONFIG }
    )
    this.checkIfIsPageWithoutMobileNav()

    this.bottomNav = this.constructBottomNav()
  },
  mounted () {
    if (this.mobileMenuWrapperRef) {
      this.eventBus.emit('app:mobile-nav-height', this.mobileMenuWrapperRef.offsetHeight)
    }

    // this.eventBus.handle('app:usercom.initialized', () => {
    this.waitForUserChat()
    // })
  }
})
export class NavbarMobile extends Mixins<IAuthMixin,
  IBaseCart,
  BodyMarginMixin,
  StructureConfigurable,
  IBaseWishListMixin>(
    AuthMixin,
    BaseCartMixin,
    BodyMarginMixin,
    StructureConfigurable,
    BaseWishListMixin
  ) {
  @Prop({ type: Object, required: false })
  public readonly logo?: NavbarLogo

  /**
   * @see NavbarProps.externalLinks
   */
  @Prop({ type: Array, required: false })
  public readonly externalLinks?: NavbarProps['externalLinks']

  /**
   * @see NavbarProps.mainLinks
   */
  @Prop({ type: Array, required: false })
  public readonly mainLinks?: NavbarProps['mainLinks']

  /**
   * Determines site's url
   */
  @Prop({ type: String, required: false })
  public readonly siteAddress?: string

  /**
   * Determines whether topbar is present.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasTopBar!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  protected readonly hasActive!: boolean

  @Inject(DrawerType, false)
  private readonly drawerService?: IDrawer

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(LocaleContentManagerType, false)
  protected readonly localeContentManager?: ILocaleContentManager

  /**
   * Reference for navigation.
   */
  @Ref('navigation')
  public readonly navigationRef?: HTMLElement

  @Ref('mobileMenuWrapper')
  public readonly mobileMenuWrapperRef?: HTMLElement

  public bottomNav: MobileMenuItemDefinition[] = []
  public config: ComponentsStructureConfig = {}
  public isPageWithoutMobileNav: boolean = false
  public isUserChatDefined: boolean = false
  public menuOpen: number | null = null
  public nestedMenuOpen: boolean = false
  public searchResultsOpen: boolean = false
  public selectedMenuItem: string | null = null

  /**
   * Determines whether cart is available for current locale
   */
  public get isCartAvailableForLocale (): boolean {
    if (!this.localeContentManager) {
      return true
    }

    return this.localeContentManager.retrieve<boolean>('cart')
  }

  public get accountLinkId (): string {
    return StaticLinks.account
  }

  public get favouriteLinkId (): string {
    return StaticLinks.favouriteProducts
  }

  public get shouldHideMobileNav (): boolean {
    return this.getConfigProperty<boolean>('shouldHideMobileNav')
  }

  public get showDynamicResults (): boolean {
    return this.getConfigProperty('showDynamicResults')
  }

  /**
   * Determines whether external links are present.
   */
  public get hasExternalLinks (): boolean {
    return typeof this.externalLinks !== 'undefined' && this.externalLinks.length > 0
  }

  /**
   * Determines is menu open.
   */
  public get isMenuOpen (): boolean {
    return this.selectedMenuItem === 'menu' || this.selectedMenuItem === 'menuWithSearch'
  }

  /**
   * Get profile page
   */
  public get profileRoute (): string {
    return `orders.${RouteNames.History}`
  }

  /**
   * Determines mobile navbar icons.
   */
  public get demandMobileNavIcons (): AnyObject {
    return this.getConfigProperty('mobileNavIcons')
  }

  /**
   * Determines navbar type.
   */
  public get navbarType (): string {
    return this.getConfigProperty('type')
  }

  /**
   * Determines whether to open drawer
   */
  public get useDrawer (): boolean {
    return this.getConfigProperty<boolean>('useDrawer')
  }

  /**
   * Determines login strategy.
   */
  public get loginStrategy (): string {
    return this.getConfigProperty('loginStrategy')
  }

  /**
   * Determines whether navbar is type `withOpenSearch`.
   */
  public get withOpenSearchType (): boolean {
    return this.navbarType === NavbarTypes.WithOpenSearch
  }

  /**
   * Async function instead of getter fixes the bug with Navbar which is visible for a millisecond
   */
  public async checkIfIsPageWithoutMobileNav (): Promise<void> {
    const response = this.$store.getters['content/response']
    if (!response) {
      this.isPageWithoutMobileNav = false
      return
    }
    this.isPageWithoutMobileNav = response.content.type === AllowedContentType.Product ||
      response.content.type === AllowedContentType.ProductCategory
  }

  /**
   * Determines whether render menu item badge.
   */
  public showBadge (element: MobileMenuItemDefinition): boolean {
    if (element.badge) {
      switch (element.id) {
        case 'cart':
          if (!this.cart) {
            return false
          }
          return this.cart.getTotalQuantity() > 0
        case 'favouriteProducts':
          return true
      }
    }

    return false
  }

  /**
   * Determines active icon for passed-in item.
   */
  public activeIcon (item: MobileMenuItemDefinition): string {
    if (item.id === 'cart' && this.cart && this.cart.getTotalQuantity() > 0) {
      return item.icon.active
    } else {
      return this.selectedMenuItem === item.id ? item.icon.active : item.icon.default
    }
  }

  /**
   * Constructs the applicable badges for given element.
   */
  public constructBadge (element: MobileMenuItemDefinition): string | number | undefined {
    if (element.badge) {
      switch (element.id) {
        case 'cart':
          return this.cart !== null ? this.cart.getTotalQuantity() : 0
        case 'favouriteProducts':
          return this.isServiceAvailable && this.wishlist ? this.wishlist.itemsCount : 0
      }
    }

    return undefined
  }

  /**
   * Builds bottom nav items.
   */
  public constructBottomNav (): MobileMenuItemDefinition[] {
    let navigationItems = Object.values(PossibleMobileMenuItems)
    const restricted: string[] = []

    if (this.localeContentManager && !this.localeContentManager.retrieve<boolean>('user')) {
      restricted.push('account')
    }

    if (this.localeContentManager && !this.localeContentManager.retrieve<boolean>('search')) {
      restricted.push('search')
    }

    if (this.localeContentManager && !this.localeContentManager.retrieve<boolean>('cart')) {
      restricted.push('cart')
    }

    navigationItems = navigationItems.filter((item) => {
      return !restricted.includes(item.id)
    })

    if (this.isUserChatDefined && (this.localeContentManager && this.localeContentManager.retrieve<boolean>(
      'cart'))) {
      navigationItems.push({
        id: 'chat',
        label: 'chat',
        icon: {
          default: 'ChatIcon',
          active: 'ChatIcon'
        }
      })
    }
    if (!this.isServiceAvailable) {
      navigationItems = navigationItems.filter(item => {
        return item.id !== this.favouriteLinkId
      })
    }

    if (!Array.isArray(this.demandMobileNavIcons) && this.demandMobileNavIcons.length === 0) {
      return navigationItems
    }

    const filteredNavigationItems: MobileMenuItemDefinition[] = []

    navigationItems.forEach((mobileIcon) => {
      const foundItem = this.demandMobileNavIcons.find((demandMobileIcon: MobileMenuItemDefinition) => mobileIcon.id.toLowerCase() === demandMobileIcon.id.toLowerCase())
      if (!foundItem) {
        return
      }

      mobileIcon.icon.default = foundItem.icon.default
      mobileIcon.icon.active = foundItem.icon.active
      filteredNavigationItems.push({
        ...mobileIcon,
        order: foundItem.order
      })
    })

    return filteredNavigationItems.sort((a, b) => {
      return parseInt(a.order || '0') - parseInt(b.order || '0')
    })
  }

  /**
   * Handles `destroy` lifecycle hook of SearchResults component.
   */
  public onDestroy (): void {
    this.hideBackdrop()
  }

  /**
   * Open drawer modal
   */
  public openDrawerLogin () {
    if (!this.drawerService) {
      return
    }

    this.drawerService.open(Drawers.Auth)
  }

  public openChat () {
    const el = document.querySelector<HTMLIFrameElement>('#usercom-launcher-dot-frame')

    const launcher = el?.contentDocument?.querySelector<HTMLDivElement>('.usercom-launcher-dot')
    if (launcher) {
      launcher.click()
    }
  }

  /**
   * Handles item select drom menu.
   */
  public onMenuItemSelect (id: string): void {
    /** Emit event, handled by AddToCardModal/Drawer that should emit close action */
    this.eventBus.emit('app:mobile-nav-click', id)

    if (id === 'menu' || id === 'menuWithSearch') {
      this.$emit('hover', false)
    } else if (id === this.accountLinkId) {
      if (this.loginStrategy === 'router') {
        this.$router.push({ name: `auth.${AuthRouteNames.Auth}` })
        return
      }

      if (this.isLoggedInUser) {
        this.$router.push({ name: `${this.profileRoute}` })
      } else {
        this.openDrawerLogin()
      }
    } else if (id === 'cart') {
      if (typeof this.drawerService === 'undefined') {
        return
      }

      if (this.useDrawer) {
        this.drawerService.open(CheckoutDrawers.Cart)
      } else {
        this.$router.push({
          name: `checkout.${CheckoutRoute.Cart}`
        })
      }
    } else if (id === 'chat') {
      this.openChat()
    } else if (id === 'logo' && this.$route.path !== '/') {
      this.$router.push('/')
    } else if (id === this.favouriteLinkId) {
      this.$router.push({ name: `wishlist.${WishlistRouteNames.Wishlist}` })
    }

    if (this.selectedMenuItem && this.selectedMenuItem === id) {
      this.selectedMenuItem = null
    } else {
      this.selectedMenuItem = id
    }

    this.nestedMenuOpen = false
  }

  /**
   * Determines whether current item has children.
   */
  public hasChildren (item: NavigationItem): boolean {
    return Array.isArray(item.children) && item.children.length > 0
  }

  /**
   * Emits event that closes backdrop.
   */
  protected hideBackdrop () {
    this.$emit('unhover')
  }

  protected waitForUserChat (): void {
    if (typeof window === 'undefined') {
      return
    }

    if (document.getElementById('usercom-launcher-dot-frame')) {
      this.isUserChatDefined = true
      return
    }

    const userComContainer = document.getElementById('usercom-widget')

    if (!userComContainer) {
      setTimeout(() => {
        this.waitForUserChat()
      }, 1000)

      return
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById('usercom-launcher-dot-frame')) {
        this.isUserChatDefined = true
        observer.disconnect()
      }
    })

    observer.observe(userComContainer, { childList: true, subtree: true })
  }

  @Watch('hasActive')
  protected onHasActiveUpdate (val: boolean, oldValue: boolean): void {
    if (val === oldValue) {
      return
    }

    if (!val) {
      this.selectedMenuItem = null
    }
  }

  /**
   * Notifies parent Navbar with events.
   *
   * This method imitates @mouseenter & @mouseleave event that can
   * be handled in parent on @hover & @unhover respectively.
   */
  @Watch('selectedMenuItem')
  protected notify (id: string): void {
    if (id === 'menu' || id === 'search' || id === 'menuWithSearch') {
      this.$emit('hover', true)
    } else {
      this.$emit('unhover')
    }
  }

  @Watch('isUserChatDefined')
  protected onIsUserChatDefinedUpdate (val: boolean, oldValue: boolean): void {
    if (val === oldValue) {
      return
    }

    this.bottomNav = this.constructBottomNav()
  }
}

export default NavbarMobile
